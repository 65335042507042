@use 'src/app/variables' as *;

.toast {
  display: flex;
  box-shadow: var(--io-shadow-dark);
  border-radius: 4px;
  font-size: var(--io-font-size-base);

  @media false and (prefers-color-scheme: dark) {
    box-shadow: none;
    border: 2px solid;
  }
}

.main {
  display: flex;
  padding: var(--io-spacing-4);
  flex: 1 1 0;
  flex-wrap: wrap;
  gap: var(--io-spacing-2);
  word-wrap: break-word;
}
.dismissible .main {
  padding-right: 0;
}

.titleWrapper {
  display: flex;
}

.title {
  display: inline-flex;
  color: var(--io-text);
  font-weight: 600;
}

.iconWrapper {
  margin-right: var(--io-spacing-2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: var(--alert-icon-color, currentColor);
}

.icon {
  width: toRem(20);
  height: toRem(20);
}

.content {
  width: 100%;
  color: var(--io-text-dimmed-700);
}
.content > *:last-child {
  margin-bottom: 0;
}

/**
 * Dismiss button
 */
.dismissWrapper {
  --dismiss-wrapper-padding: var(--io-spacing-2);
  padding-top: var(--dismiss-wrapper-padding);
  padding-right: var(--dismiss-wrapper-padding);
  padding-left: var(--dismiss-wrapper-padding);
}

/**
 * Variants
 */
.success {
  --alert-icon-color: var(--io-success);
  background-color: var(--io-success-faded);
  border-color: var(--io-success-medium);
}
.warning {
  --alert-icon-color: var(--io-warning);
  background-color: var(--io-warning-faded);
  border-color: var(--io-warning-medium);
}
.error {
  --alert-icon-color: var(--io-danger);
  background-color: var(--io-danger-faded);
  border-color: var(--io-danger-medium);
}
.info {
  --alert-icon-color: var(--io-info);
  background-color: var(--io-info-faded);
  border-color: var(--io-info-medium);
}
